import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/ImagePopupStyle';
import Settings from '../../Settings/Settings'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Popover from '@material-ui/core/Popover';
import AddToCartButton from './AddToCartButton'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import $ from "jquery";
import AddedToCartDialogComponent from '../AddedToCartDialog/AddedToCartDialogComponent'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Helpers from '../../../../Core/Helpers';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { Typography } from '@material-ui/core';
import MediaQuery from '../../../../CommonComponents/UseMediaQuery/MediaQueryComponent';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import ImageAlbums from './ImageAlbums';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Chip from '@material-ui/core/Chip';
import {
  Link
} from "react-router-dom";
import ImageContainerDescription from '../StaticPagesComponent/MaterialComponents/ImageContainerDescription';
import ExifPopover from './ExifPopover';

function getLocalStorage(storageName = 'state') {
  // We need the try block because user may not permit our accessing localStorage.
  try {
      const serializedState = localStorage.getItem(storageName);
      if (serializedState === null) { // The key 'state' does not exist.
          return undefined;             // Let our reducer initialize the app.
      }

      return JSON.parse(serializedState)

  } catch (error) {
      return undefined // Let our reducer initialize the app.
  }
}

const useStyles = makeStyles((theme) => (Style(theme)));

export default function ImagePopup(props) {
  const Helper = new Helpers()
  const classes = useStyles();
  const [image, setImage] = React.useState(null)
  const [commentText, setCommentText] = React.useState("")
  const [commentFirstName, setCommentFirstName] = React.useState((getLocalStorage("user")?getLocalStorage("user").usersFirstName:""))
  const [commentLastName, setCommentLastName] = React.useState((getLocalStorage("user")?getLocalStorage("user").usersLastName:""))
  const [comments, setComments] = React.useState([])
  const [imagesCommentsPagination, setImagesCommentsPagination] = React.useState(1)
  const [emptyCommentsList, setEmptyCommentsList] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [likes, setLikes] = React.useState({})
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getImageDetailsAction()
    getComments()
    postWebsiteVisitors()

      const searchParams = new URLSearchParams(document.location.search)
      if (searchParams.get('affiliate'))
          localStorage.setItem('affiliate', JSON.stringify({affiliate: searchParams.get('affiliate')}))
  }, []);

  const getImageDetailsAction = () => {
    let headers = {}
    if (componentMethods.getLocalStorage('user'))
    {
      headers['X-Account-Token'] = componentMethods.getLocalStorage('user').tokensHash
    }
    $.ajax({
        url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_IMAGES + '/' + props.urlParams.image,
        type: 'GET',
        headers: headers,
        data: {
          
        },
        success: function (data) {
              if(data.items.length) {
                setImage(data.items[0])
                setLikes(data.items[0].counts)
              }
        },
        error: function (request, status, error) {
          let errors = componentMethods.getManagerState().errors
          errors.push(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"])
          componentMethods.setManagerState({errors:errors})
        },
        dataType: "json",
        cache: false,
        contentType: false,
        processData: false
    });
  }
  const closeImage = () => {
      const pathNameSplit = window.location.pathname.split("image")
      if (image)
      {
        pathNameSplit[0] += '#product-' + image.imagesID
      }
      props.changeRoute(pathNameSplit[0])
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const componentConstants = {
      ...props.componentConstants,
      language: props.getManagerState().language
  }

  const getComments = () => {
    let headers = {}
    if (componentMethods.getLocalStorage('user'))
    {
      headers['X-Account-Token'] = componentMethods.getLocalStorage('user').tokensHash
    }
    $.ajax({
      url: Settings.API_URL + Settings.API_ENDPOINT_IMAGES_COMMENTS_BY_PATH + '/' + props.urlParams.image,
      type: 'GET',
      headers: headers,
      data: {},
      success: function (data) {
        setComments(data.items)
        setImagesCommentsPagination(data.pagination)
        if (data.items.length === 0) setEmptyCommentsList(true)
        else  setEmptyCommentsList(false)
      },
      error: function (request, status, error) {
        let errors = componentMethods.getManagerState().errors
        errors.push(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE_COMMENTS"])
        componentMethods.setManagerState({errors:errors})
      },
      dataType: "json",
      cache: false,
      contentType: false,
      processData: false
    });
  }

  const postImagesLikesAction = () => {

    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + image.imagesID, {},'POST',null, (data) => {
       getImagesLikesAction(true)
    })
  }

  const deleteImagesLikesAction = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + image.imagesID, {},'DELETE',null, (data) => {
       getImagesLikesAction(true)
    })
  }
  const getImagesLikesAction = (force = false) => {
    if (image.counts && !force)
    {
      setLikes(image.counts)
    } else {
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + image.imagesID, {},'GET',null, (data) => {
        setLikes(data.items)
      })
    }
  }

  const postComment = () => {
    let postObject = {imagesCommentsImagesID:image.imagesID, imagesCommentsText: commentText, imagesCommentsFirstName: commentFirstName, imagesCommentsLastName: commentLastName}
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_COMMENTS,postObject,'POST',"etc", () => {
          getComments()
          setCommentText("")
        })
  }
  const postWebsiteVisitors = () => {

    let postObject = {
        websiteVisitorsReferrer: document.referrer,
        websiteVisitorsSection: Settings.API_URL,
        websiteVisitorsRequestUri: window.location.href
    }
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
}
  const getMoreComments = () => {
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_COMMENTS_BY_PATH + '/' + props.urlParams.image + '?page=' + imagesCommentsPagination.next,{},'GET',"etc", (data) => {
            let existingComments = comments
            existingComments = existingComments.concat(data.items)
            setImagesCommentsPagination(data.pagination)
            setComments(existingComments)
        })
  }
  const componentMethods = {
      ...props.componentMethods,
      handleClosePopover: () => {
        handleClose()
      },
      setManagerState: (stateObject, callback = () => {}) => {
        props.setManagerState(stateObject, callback)
      },
      getManagerState: () => {
        return props.getManagerState()
      },
      getLocalStorage: (storageName = 'state') => {
        // We need the try block because user may not permit our accessing localStorage.
        try {
            const serializedState = localStorage.getItem(storageName);
            if (serializedState === null) { // The key 'state' does not exist.
                return undefined;             // Let our reducer initialize the app.
            }

            return JSON.parse(serializedState)

        } catch (error) {
            return undefined // Let our reducer initialize the app.
        }
      },
      ajax: (url, 
        dataObject = {}, 
        method, stateValue = '', 
        callback = () => {}, 
        headers = {}, 
        setState = true, 
        catchCallback = () => {},
        errorCallback = () => {}, ignoreUser = false) => {
          // adauga o fct de callback pe eroare (catchCallback) pe care o executi in catch
          let sendDataObj = {};
          let dataURLSearchParams = new URLSearchParams();
          for (let pDO in dataObject) {
              if (dataObject.hasOwnProperty(pDO)) {
                  sendDataObj[pDO] = dataObject[pDO];
                  dataURLSearchParams.append(pDO, dataObject[pDO]);
              }
          }
          if (!headers["X-Account-Token"])
          {
              // If your expected result is "http://foo.bar/?x=1&y=2&x=42"
              if (componentMethods.getLocalStorage('user') && !ignoreUser)
              {
                  headers["X-Account-Token"] = componentMethods.getLocalStorage('user').tokensHash
              }
          }

          let stateSet = {};
          let requestObject = {
              method: method,
              mode: "cors",
              cache: "no-cache",
              headers: headers,
          };

          switch(method) {
              case 'GET':
                  break;
              default:
                  requestObject.body = dataURLSearchParams
                  break;
          }

          fetch(url, requestObject).then((response) => {
              const res = response.json();
              return res;
          }).then((data) => {
              if (data.success === true || data.httpStatusCode === 200) {
                      callback(data);
              } else {
                  catchCallback(data);
              }
          }).catch((error) => {
              errorCallback()
          });
        }

  }

  const componentProps = {
      ...props.componentProps
  }
  return (
    <div className={classes.root}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <div className={classes.img} >
                    <img src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.urlParams.image} style={{bottom: 0, maxWidth: "100%", maxHeight: '100vh', margin:'0 auto', display: "block"}} alt={image ? componentConstants.language.LANG_WEBSITE_MOTO + componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + image.usersFirstName + " " + image.usersLastName + " " + image.imagesLocationName + " " + image.imagesDescription + '' : null}/>
                </div>
                <div>
                  <Button onClick={closeImage} variant="contained" color="secondary" className={classes.button}>
                    <ArrowBackIcon />{componentConstants.language.LANG_BACK}
                  </Button>
                  {Helper.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_IMAGE_DOWNLOADER) ?
                    <a href={Settings.API_URL + Settings.API_ENDPOINT_FILE + props.urlParams.image} target="_blank">
                      <Button variant="contained" color="secondary" className={classes.buttonDownload}>
                        <OpenWithIcon />
                      </Button>
                    </a>
                  :
                    null
                  }       
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
              {image? 
                  <div style={{padding: 8}}>
                  <Paper>
                    <div style={{margin: 8}}>
                    {parseInt(likes.isLiked) > 0 ?
                      <Button startIcon={<FavoriteIcon />} onClick={deleteImagesLikesAction}>{componentConstants.language.LANG_I_LIKE} ({likes.likes})</Button>
                    :
                    null
                    }
                    {parseInt(likes.isLiked) === 0 ?
                      <Button startIcon={<FavoriteBorderIcon />} onClick={postImagesLikesAction}>{likes.likes}</Button>
                    :
                    null
                    }
                    <Button startIcon={<ShareIcon />} onClick={() => {
                    navigator.clipboard.writeText(Settings.LINKS_SITE_URL + 'image/' + image.imagesPath)
                    componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                    }}>{componentConstants.language.LANG_SHARE}</Button>
                    {image.exif ?
                      <ExifPopover element={image} />
                      :
                      null
                    }
                </div>
                    <div style={{display:"inline-block"}}>
                      <Link to={"/user/pictures/" + image.imagesUsersID} ><Chip clickable label={image.usersFirstName + ' ' + image.usersLastName} color="primary" /></Link>
                    </div>
                    <ImageAlbums canAddToAlbum={false} image={image} componentConstants={componentConstants} componentMethods={componentMethods} />
                  </Paper>
                  </div>
                  :
                  null}
                <div style={{padding: 8}}>
                  {image?
                    <Paper style={{padding: 8, marginBottom: 8}}>
                      <Typography variant="subtitle2">{componentConstants.language.LANG_PHOTOGRAPHER}: {image.usersFirstName + ' ' + image.usersLastName}</Typography>
                      {image.imagesDescription?
                        <Typography variant="subtitle2">{componentConstants.language.LANG_UPLOAD_IMAGE_DESCRIPTION}: {image.imagesDescription}</Typography>
                        :
                        null
                      }
                      {image.imagesLocationName?
                        <Typography variant="subtitle2">{componentConstants.language.LANG_UPLOAD_IMAGE_LOCATION_NAME}: {image.imagesLocationName}</Typography>
                        :
                        null
                      }
                    </Paper>
                  :
                  null
                  }
                <Paper style={{marginBottom: 8, padding: 8}}>
                {image? 
                  <React.Fragment>
                    <Typography variant="h3" component="h3">{componentConstants.language.LANG_ADD_TO_SHOPPING_CART}</Typography>
                    <AddToCartButton nativeSelect={true} image={image} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
                  </React.Fragment>
                  :
                  null
                }
                </Paper>
                <Paper style={{padding: 8}}>
                <Typography variant="subtitle2">{componentConstants.language.LANG_IMAGE_POPUP_SUBTITLE_1}</Typography>
                <Typography variant="h5" component="h5">{componentConstants.language.LANG_IMAGE_POPUP_TITLE_3}</Typography>
                <Typography variant="subtitle2">{componentConstants.language.LANG_IMAGE_POPUP_SUBTITLE_2}</Typography>
                <ImageContainerDescription />
                </Paper>
                </div>
                {image ? 
                    <div style={{padding: 8}}>
                      <Typography variant="h3" component="h3">{componentConstants.language.LANG_COMMENTS}</Typography>
                    <Paper>
                  <TextField
                      id="filled-multiline-flexible"
                      label={componentConstants.language.LANG_FIRST_NAME}
                      variant="filled"
                      value={commentFirstName}
                      onChange={(e) => {setCommentFirstName(e.target.value)}}
                      fullWidth
                      color="secondary"
                      size="small"
                  />
                  <TextField
                      id="filled-multiline-flexible"
                      label={componentConstants.language.LANG_LAST_NAME}
                      variant="filled"
                      value={commentLastName}
                      onChange={(e) => {setCommentLastName(e.target.value)}}
                      fullWidth
                      color="secondary"
                      size="small"
                  />
                  <TextField
                      id="filled-multiline-flexible"
                      label={componentConstants.language.LANG_COMMENT}
                      multiline
                      maxRows={4}
                      rows={4}
                      variant="filled"
                      value={commentText}
                      onChange={(e) => {setCommentText(e.target.value)}}
                      fullWidth
                      color="secondary"
                      size="small"
                  />
                  <Button fullWidth style={classes.sendCommentButton} onClick={postComment} endIcon={<SendIcon />} variant="contained" color="secondary">{componentConstants.language.LANG_SEND_COMMENT}</Button>
                  </Paper>
                  </div>
                :
                null}
                  <React.Fragment>
                    {emptyCommentsList ?
                      <Typography variant="body2" gutterBottom>{componentConstants.language.LANG_EMPTY_COMMENTS_LIST}</Typography>
                    :
                    null
                    }
                    <div style={{padding: 8}}>
                      {
                      comments.map((item, index) => {
                          return <Paper variant="outlined" className={classes.commentPager} key={index}>
                                  <Typography variant="subtitle2" gutterBottom>{(item.imagesCommentsFirstName ? item.imagesCommentsFirstName : componentConstants.language.LANG_ANONYMOUS) + ' ' + (item.imagesCommentsLastName ? item.imagesCommentsLastName : componentConstants.language.LANG_ANONYMOUS)}:</Typography>
                                  <Typography variant="subtitle1" gutterBottom>{item.imagesCommentsText}</Typography>
                                </Paper>
                        })
                      }
                      {imagesCommentsPagination.current < imagesCommentsPagination.last ?
                        <Button onClick={getMoreComments}>{componentConstants.language.LANG_LOAD_MORE_COMMENTS}</Button>
                      :
                      null}
                    </div>
                  </React.Fragment>
              </Grid>
            </Grid>
    </div>
  );
}