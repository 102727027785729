import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import LensIcon from '@material-ui/icons/Lens';
import ExposureIcon from '@material-ui/icons/Exposure';
import TimerIcon from '@material-ui/icons/Timer';
import CameraIcon from '@material-ui/icons/Camera';
import DescriptionIcon from '@material-ui/icons/Description';
import RoomIcon from '@material-ui/icons/Room';
import ShutterSpeedIcon from '@material-ui/icons/ShutterSpeed';
import IsoIcon from '@material-ui/icons/Iso';
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    padding: 8
  }
}));

export default function ExifPopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{zIndex: '10000000'}}>
      <IconButton aria-describedby={id} onClick={handleClick}>
      <InfoIcon />
      </IconButton>
      <Popover
      style={{zIndex: '10000000'}}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      {props.element.exif['Make'] ?
        <div className={classes.popover}><CameraAltIcon style={{marginRight: 8}}/>{props.element.exif['Make'] + ' ' +  (props.element.exif['Model']? props.element.exif['Model'] : "")}</div>
        :
        null
      }
      {props.element.exif['UndefinedTag:0xA434'] ?
        <div className={classes.popover}><LensIcon style={{marginRight: 8}} />{props.element.exif['UndefinedTag:0xA434']}</div>
        :
        null
      }
      {props.element.exif['ExposureTime'] ?
        <div className={classes.popover}><ShutterSpeedIcon style={{marginRight: 8}} />{props.element.exif['ExposureTime']}</div>
        :
        null
      }
      {props.element.exif['FNumber'] ?
        <div className={classes.popover}><CameraIcon style={{marginRight: 8}} />F{eval(props.element.exif['FNumber'])}</div>
        :
        null
      }
      {props.element.exif['ISOSpeedRatings'] ?
        <div className={classes.popover}><IsoIcon style={{marginRight: 8}} />ISO{props.element.exif['ISOSpeedRatings']}</div>
        :
        null
      }
      {props.element.imagesDescription ?
        <div className={classes.popover}><DescriptionIcon style={{marginRight: 8}} />{props.element.imagesDescription}</div>
        :
        null
      }
      {props.element.imagesLocationName ?
        <div className={classes.popover}><RoomIcon style={{marginRight: 8}} />{props.element.imagesLocationName}</div>
        :
        null
      }
      </Popover>
    </div>
  );
}