import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/ImageContainerStyle';
import Settings from '../../../Settings/Settings'
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import color from '@material-ui/core/colors/teal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
    imagesContainer: {
      height: 310,
      overflowX: 'scroll',
      display: 'inline-flex',
      marginBottom: 8,
      width: '100%'
    },
    imageElement: {
      height: 250,
      display: 'inline-block',
      width: 'auto',
      position: 'relative',
      borderRadius: 5
    },
    img: {
      height: '100%',
      borderRadius: 5,
      margin: '0 5px',
      borderColor: color[500],
      boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    avatarsContainer: {
      position: 'absolute',
      left: 8,
      bottom: 0
    },
    nextButton: {
      width: 250,
      minWidth: 250,
      height: 252,
      padding: 8
    },
    addToCartButton: {
    },
    addToCartButtonContained: {
      padding: 8
    },
    aUsersLink: {
        color: color[500]
    }
  }));

export default function SearchAlbumsContainer(props) {
    const classes = useStyles();
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  
  return (
    <React.Fragment>
            {componentMethods.getManagerState().albumsSearchResultset.length > 0 ?
            <React.Fragment>
                <Paper style={{padding: 8, marginBottom: 8, marginTop: 8, position: 'relative'}}>
                    <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{componentConstants.language.LANG_ALBUMS}</Typography>
                    <IconButton style={{position: 'absolute', top: 0, left:32, zIndex: 200}} variant="contained" color="secondary" onClick={() => {document.getElementById('albumsContainer').scrollLeft -= 150 }}><ChevronLeftIcon /></IconButton>
                    <IconButton style={{position: 'absolute', top: 0, right: 32, zIndex: 200}} variant="contained" color="secondary" onClick={() => {document.getElementById('albumsContainer').scrollLeft += 150 }}><ChevronRightIcon /></IconButton>
                </Paper>
                <div className={classes.imagesContainer} id="albumsContainer">
                {componentMethods.getManagerState().albumsSearchResultset.map((el, index) => {
                    return <div key={index} className={classes.imageElement}>
                        <a href={'/albums/'+el.albumsID}>
                        {el.albumsImagesPath?
                            <img className={classes.img} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.albumsImagesPath+ '?thumbsWidth=375'} alt={el ? componentConstants.language.LANG_WEBSITE_MOTO + componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + el.usersFirstName + " " + el.usersLastName + " " + el.imagesLocationName + " " + el.imagesDescription + '' : null} />
                            :
                            <React.Fragment>
                            {
                              el.imagesPath?
                              <img className={classes.img} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.imagesPath+ '?thumbsWidth=375'} alt={el ? componentConstants.language.LANG_WEBSITE_MOTO + componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + el.usersFirstName + " " + el.usersLastName + " " + el.imagesLocationName + " " + el.imagesDescription + '' : null} />
                              :
                              <img className={classes.img} src= {Settings.SETTING_STATIC_SITE_URL+ '/empty-painting-icon.png?thumbsWidth=375'} alt={el ? componentConstants.language.LANG_WEBSITE_MOTO + componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + el.usersFirstName + " " + el.usersLastName + " " + el.imagesLocationName + " " + el.imagesDescription + '' : null} />
                            }
                            </React.Fragment>
                        }
                        </a>
                        <Grid container>
                            <Grid item xl={12} xs={12} lg={12}>
                            <IconButton color="secondary" className={classes.addToCartButtonContained} onClick={() => {
                                navigator.clipboard.writeText(Settings.LINKS_SITE_URL + 'albums/'+el.albumsID)
                                componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                                }}><ShareIcon /></IconButton>
                            </Grid>
                            <div className={classes.avatarsContainer}>
                            <Paper style={{padding:8}}>
                                    <Typography variant="subtitle2" component="p">{el.albumsName} 
                                        {el.usersID ?
                                        <React.Fragment>&nbsp;{componentConstants.language.LANG_BY} <a className={classes.aUsersLink} href={"/user/pictures/" + el.usersID}>{el.usersFirstName} {el.usersLastName}</a></React.Fragment>
                                        :
                                        null}</Typography>
                                </Paper>
                            </div>
                        </Grid>
                    </div>
                        })}
                        {!componentMethods.getManagerState().loadingAlbumsSearch  && componentMethods.getManagerState().currentAlbumsSearchPagination.last > componentMethods.getManagerState().currentAlbumsSearchPagination.current ?
                        <Button color="secondary" variant="contained" className={classes.nextButton} onClick={() => {componentMethods.changeAlbumsSearchPage(componentMethods.getManagerState().currentAlbumsSearchPagination.next)}}>
                        <Typography variant="subtitle2" component="p">{componentConstants.language.LANG_MORE_ALBUMS}</Typography>
                        <NavigateNextIcon />
                        </Button>  
                        :
                        null
                    }
                    {componentMethods.getManagerState().loadingAlbumsSearch ?
                    <LoadingComponent />
                    :
                    null}
                </div>
            </React.Fragment>
            :
            null}
    </React.Fragment>
  );
}