import { red } from '@material-ui/core/colors';
export default function Style(theme) {
  var body = document.body,
    html = document.documentElement;
    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                          html.clientHeight, html.scrollHeight, html.offsetHeight );

    const style = {
        root: {
          maxWidth: 345,
        },
        media: {
          height: 0,
          paddingTop: '56.25%', // 16:9
        },
        expand: {
          transform: 'rotate(0deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        },
        avatar: {
          backgroundColor: red[500],
        },
        image: {
          maxHeight: "100%",
          width: "100%",
          maxWidth: 640
        },
        imageContainer: {
          position: "relative",
          width: 'auto',
          maxWidth: '100%',
          width: "auto",
          height: "100%",
          margin: '0 auto'
        },
        buttons: {
          margin: 2,
          padding: 8
        },
        buttonsNextPrev: {
          margin: 2,
          padding: 8,
          backgroundColor: '#FFF'
        },
        buttonsContainer: {
          position: "absolute",
          bottom: 100,
          zIndex: 250,
          maxWidth: '100%',
          width: '390px',
          margin: '0 auto'
        },
        container: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          margin: "0 auto"
        }
      }
    return style
}