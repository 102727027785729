import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import {
    Link
  } from "react-router-dom";

import WhatshotIcon from '@material-ui/icons/Whatshot';
import color from '@material-ui/core/colors/teal';
const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  moreButton: {
    marginLeft: 8
  }
}));

export default function Floatbar(props) {
  const classes = useStyles();
  const componentConstants = {
    ...props.componentConstants
}

const componentMethods = {
    ...props.componentMethods
}
const componentProps = {
    ...props.componentProps
}
  return (
    <React.Fragment>
        <div style={{position: "fixed", bottom: 86, borderRadius: 5, backgroundColor: color[100]}}>
            <Link to="/foto/like"aria-label="Imaginile tale" className={classes.yourImagesLink}>
                <IconButton>
                    <WhatshotIcon color="secondary"/>
                </IconButton>
            </Link>            
        </div>
    </React.Fragment>
  );
}