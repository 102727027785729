import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import Grid from '@material-ui/core/Grid';
import ImageFotoLikeContainer from './MaterialComponents/ImageFotoLikeContainer';

export default class FotoLikeComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.state = {
            currentIndex: 0
        }
    }

    componentDidMount() {
        super.componentDidMount()
        let endpoint = Settings.API_ENDPOINT_RANDOM_IMAGES_NOT_LIKED

        this.getSearchImages(endpoint)


        // subscribe("scroll", () => this.automaticallyLoadOlder())
    }

    moveToNextImage()
    {
        if (this.state.currentIndex === (this.props.getManagerState().fotoLikeResultset.length - 7))
        {
            let endpoint = Settings.API_ENDPOINT_RANDOM_IMAGES_NOT_LIKED
            this.getSearchImages(endpoint)
        }
        let cI = this.state.currentIndex
        cI++
        this.setState({currentIndex: cI})
    }


    moveToPreviousImage()
    {
        let cI = this.state.currentIndex
        if (cI > 0)
        {
            cI--
        }
        this.setState({currentIndex: cI})
    }
    getSearchImages(endpoint) {
        let that = this
        this.props.setManagerState({loadingFotoLikeResultset: true}, () => {
            that.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let fLR = that.props.getManagerState().fotoLikeResultset
                if (fLR.length > 0) {
                    fLR = fLR.concat(data.items)
                    console.log(fLR.length)
                } else {
                    fLR = data.items
                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: window.location.href
                    }
                    that.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                }
                that.props.setManagerState({fotoLikeResultset: fLR, loadingFotoLikeResultset: false})
            })
        })
    }


    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            page: "fotolike",
            urlParams: that.props.urlParams,
            hideFloatbar: true
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            moveToNextImage: () => {
                this.moveToNextImage()
            },
            moveToPreviousImage: () => {
                this.moveToPreviousImage()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
                hasExactAccess: (accessLevel) => {
                    return that.hasExactAccess(accessLevel)
                },
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SearchAllImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchAllImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '65px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              }
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            nextButton: {
                width: '100%',
                minWidth: 250,
                height: 252,
                padding: 8
            }
          };
        
        this.loadComponentsProps()    

        
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                        {this.props.getManagerState().fotoLikeResultset.length > 0?
                            <ImageFotoLikeContainer {...this.buildComponentProps('AppbarComponent')} element={this.props.getManagerState().fotoLikeResultset[this.state.currentIndex]} />
                            :
                            null
                        }
                            
                        </Grid>
                    </Grid>
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}