import React, { useEffect } from 'react';
import Style from '../Styles/ImageContainerStyle'
import Settings from '../../../Settings/Settings'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhotographersAvatar from '../../HomeComponent/MaterialComponents/PhotographersAvatar';
import AddToCartButton from '../../ImageComponent/AddToCartButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Popover from '@material-ui/core/Popover';
import color from '@material-ui/core/colors/teal';

const useStyles = makeStyles((theme) => (Style(theme)));


function AddToCartPopover(props) {

    const useStyles = makeStyles((theme) => ({
        imagesContainer: {
        height: 310,
        overflowX: 'scroll',
        display: 'inline-flex',
        marginBottom: 8,
        width: '100%'
        },
        imageElement: {
        height: 250,
        display: 'inline-block',
        width: 'auto',
        position: 'relative',
        borderRadius: 5
        },
        img: {
        height: '100%',
        borderRadius: 5,
        margin: '0 5px',
        borderColor: color[500],
        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        },
    avatarsContainer: {
      position: 'absolute',
      left: 8,
      bottom: 0
    },
    nextButton: {
      width: 250,
      minWidth: 250,
      height: 252,
      padding: 8
    },
    addToCartButton: {
    },
    addToCartButtonContained: {
      margin: 8
    },
    aUsersLink: {
        color: color[500]
    }
  }));
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const componentConstants = {
      ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div style={{padding: 8}}>
        <div className={classes.addToCartButtonContained}> 
        <Button className={classes.addToCartButton} fullWidth onClick={handleClick} color="secondary" variant="contained"><AddShoppingCartIcon /></Button>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <AddToCartButton image={props.image} componentConstants={componentConstants} componentMethods={componentMethods} />
        </Popover>
      </div>
    );
  }

export default function ImageFotoLikeContainer (props) {
  const classes = useStyles();
  const [showImageLoader, setShowImageLoader] = React.useState(true)
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const postImagesLikesAction = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + props.element.imagesID, {},'POST',null, (data) => {
       componentMethods.moveToNextImage()
    })
  }
  const postImagesNotLikesAction = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_NOT_LIKES + '/' + props.element.imagesID, {},'POST',null, (data) => {
       componentMethods.moveToNextImage()
    })
  }
  return <div className={classes.container}>
            <div className={classes.imageContainer}>
                <img onLoad={() => {setShowImageLoader(false)}} className={classes.image} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath+ '?thumbsWidth=800'} />
                <div style={{position: "absolute", left: 0,top: 0, zIndex: 260}}>
                    <div style={{display: "inline-block"}}><a href={"/user/pictures/" + props.element.imagesUsersID} ><PhotographersAvatar usersID={props.element.imagesUsersID} el={props.element} componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} /></a></div>
                    <div style={{display: "inline-block"}}><AddToCartPopover componentConstants={componentConstants} componentMethods={componentMethods} image={props.element} /></div>
                </div>
            </div>
            <div className={classes.buttonsContainer}>
                <div>
                    <IconButton size="small" className={classes.buttonsNextPrev} onClick={() => { componentMethods.moveToPreviousImage()}} color="secondary"><ChevronLeftIcon /></IconButton>
                    <Button size="small" color="primary" onClick={() => {postImagesLikesAction()}} variant="contained" className={classes.buttons} startIcon={<FavoriteIcon />}>{componentConstants.language.LANG_I_LIKE}</Button>
                    <Button size="small" color="secondary" onClick={() => {postImagesNotLikesAction()}} variant="contained" className={classes.buttons} startIcon={<ClearIcon />}>{componentConstants.language.LANG_I_DONT_LIKE}</Button>
                    <IconButton size="small" className={classes.buttonsNextPrev} onClick={() => { componentMethods.moveToNextImage()}} color="secondary"><ChevronRightIcon /></IconButton>
                </div>
            </div>
        </div>
}