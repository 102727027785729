import React from "react";
import {
    Router
} from "react-router-dom";
import RouterSwitch from "./RouterSwitch";
import history from './History';
import Languages from "../../../Languages/Languages";
import Settings from '../Settings/Settings'
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";
import Theme from '../../../Themes/ThemeArtLexie'
import { MuiThemeProvider } from '@material-ui/core/styles';
class RouterComponent extends React.Component {
    constructor(props) {
        super(props);
        let lang = Languages[Settings.defaultLanguage]

        this.state = {
            language: lang,
            encryption: {},
            showSetEncryption: false,
            conversation: '',
            scrollPosition: {},
            unreadMessagesBadges: {},
            currentMessage: {},
            userList: {},
            pingWebsocket: true,
            messages: [],
            errors: [],
            createAccountUsersFirstName: "",
            createAccountUsersLastName: "",
            createAccountUsersEmail: "",
            createAccountUsersDateOfBirthDay: "",
            createAccountUsersDateOfBirthMonth: "",
            createAccountUsersDateOfBirthYear: "",
            createAccountUsersPassword: "",
            createAccountUsersPasswordConfirm: "",
            formInvalidCreateAccountPassword: false,
            formInvalidCreateAccountPasswordConfirm: false,
            createAccountUsersSexID: "",
            yourAccountUsersSexID: "",
            yourAccountUsersDateOfBirthDay: "",
            yourAccountUsersDateOfBirthMonth: "",
            yourAccountUsersDateOfBirthYear: "",
            logInUsersEmail: "",
            logInUsersPassword: "",
            createAccountYears: {
                items: []
            },
            counties: {
                items: []
            },
            addresses: {
                items: []
            },
            deliveryMethods:[],
            orders: [],
            userOrders: [],
            rewards: [],
            payments: [],
            userOrderItems: [],
            searchQuery: "",
            successfulUsersRegister: false,
            imagesResultset: [],
            loadingCreateAccount: false,
            loadingLogin: false,
            loadingYourAccount: false,
            loadingShoppingCartItems: false,
            shoppingCartItems: [],
            deliveryAddress: null,
            billingAddress: null,
            openOrderItemsDialog: false,
            showCurrentRewards: true,
            shoppingCartTotal: 0,
            retours: [],
            albumsResultset: [],
            loadingAlbums: false,
            userAlbums: [],
            loadingUserAlbums: false,
            albumsImagesResultset: [],
            loadingAlbumsImages: false,
            albumInfo: null,
            albumsSearchResultset: [],
            stocks: [],
            loadingStocks: false,
            stocksImages: [],
            loadingStocksImages: false,
            showAddedToCartDialog: false,
            vouchers: [],
            loadingVouchers: false,
            voucherShoppingCart: null,
            emptyUserOrder: true,
            userOrder: [],
            emails: [],
            loadingEmails: false,
            users: [],
            loadingUsers: false,
            websiteVisitors: [],
            loadingWebsiteVisitors: false,
            loadingAffiliatesPayments: false,
            affiliatesPayments: [],
            adminAffiliatePayments: [],
            loadingAdminAffiliatesPayments: false,
            affiliatesInfo: [],
            loadingUsersAffiliatesInfo: false,
            variantsDimensions: [],
            loadingVariantsDimensions: false,
            photographersResults: [],
            loadingPhotographers: false,
            loadedPage: 1,
            noMoreImages: false,
            loadingRandomImages: false,
            randomImagesResultset: [],
            myFavoriteImages: [],
            loadingMyFavoriteImages: false,
            profilePictures: {},
            fotoLikeResultset: [],
            loadingFotoLikeResultset: false
        }
    }

    componentDidMount() {
    }
    clearErrorMessage(index) {
        let errorsMessages = this.state.errors
        errorsMessages.splice(index, 1);
        this.setManagerState({errors: errorsMessages})
    }
    setManagerState(stateObject, callback = () => {}) {
        if(stateObject) {
            this.setState(stateObject,() => {
                this.refresh(callback)
            })
        }
    }

    getManagerState() {
        return this.state
    }
    render() {
        return (<MuiThemeProvider theme={Theme}>
            <Router history={history}>
                <RouterSwitch
                history={history} 
                getManagerState={() => { return this.getManagerState()}}
                websocketSend={(data, type)=>{this.websocketSend(data, type)}}
                setManagerState={(stateObject, callback) => {this.setManagerState(stateObject, callback)}}
                setScrollPosition={(scrollPos) => {this.setScrollPosition(scrollPos)}}
                clearErrorMessage={(index) => {this.clearErrorMessage(index)}}
                />
                <FooterComponent setManagerState={(stateObject, callback = () => {}) => {return this.setManagerState(stateObject, callback)}} getManagerState={() => { return this.getManagerState()}} />
            </Router>
            </MuiThemeProvider>
        );
    }
    refresh(callback = () => {}) {
        this.forceUpdate()
        callback()
    }
}

export default RouterComponent