import React, { useState, useEffect } from 'react';
import Settings from '../../Settings/Settings'
import $ from "jquery";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import {
    Link
  } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    quantityInput: {
        margin: theme.spacing(1)
    },
    rootTotal: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        display:"inline-block"
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '',
      },
      button: {
        marginTop: theme.spacing(1)
      },  
      buttonLink: {
        width: "100%"
      },
      link: {
        margin: 8,
        display: "block",
        textDecoration: 'none'
      },
      variantsItem: {
        border: '2px solid #009688',
        borderRadius: 5,
        padding: 8,
        cursor: 'pointer',
        margin: 8
      },
      variantsSelected: {
        border: '2px solid #009688',
        borderRadius: 5,
        padding: 8,
        backgroundColor: '#009688',
        color: '#fff',
        cursor: 'pointer',
        margin: 8
      },
      variantsPrice: {
        fontSize: 18,
        fontWeight: 'bold',
      },
      variantsDimensions: {
        fontSize: 12,
        fontWeight: 'bold'
      }
  }));

export default function AddToCartButton(props) {
    const [variants, setVariants] = useState([]);
    const classes = useStyles();
    const [variant, setVariant] = React.useState('');
    const [quantity, setQuantity] = React.useState(1)
    const [addedToCart, setAddedToCart] = React.useState(false)
    const [loadingVariants, setLoadingVariants] = React.useState(true)
    const handleChange = (event) => {
        setVariant(event.target.value);
    };

    const handleChangeQuantity = (event) => {
        setQuantity(parseInt(event.target.value))
    }
    let subtotal = 0;
    for (let vi in variants) {
        if(variants[vi].variantsID === variant) {
            subtotal = (quantity * (parseInt(variants[vi].variantsDimensionsPrice)/100).toFixed(2)).toFixed(2)
        }
    }
    const componentConstants = {
      ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    useEffect(() => {
    getVariants()
    }, []);
    const getVariants = () => {
      if (props.image.variants)
      {
        setLoadingVariants(false)
        if (props.image.variants.items.length === 1)
        {
          setVariant(props.image.variants.items[0].variantsID)
        }        
        setVariants(props.image.variants.items)
      } else {
        $.ajax({
          url: Settings.API_URL + Settings.API_ENDPOINT_IMAGES_VARIANT+ props.image.imagesID,
          type: 'GET',
          success: function (data) {
              if (data.items.length === 1)
              {
                setVariant(data.items[0].variantsID)
              }
              setVariants(data.items)
              setLoadingVariants(false)
          }
      });

      }
    }
    const getShoppingCartTotal = () => {
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_SHOPPINGCART_TOTAL + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, {},'GET','addresses', (data) => {
            componentMethods.setManagerState({shoppingCartTotal: data.items.total})
        })
    }
    const postShoppingCartItemsAction = () => {
      let url = Settings.API_URL + Settings.API_ENDPOINT_SHOPPINGCARTITEMS + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha

      let dataObject = {
        shoppingCartItemsVariantsID: variant,
        shoppingCartItemsQuantity: quantity
      }
      componentMethods.ajax (
        url, 
        dataObject, 
        'POST', 
        true, 
        (data) => {
            if(componentMethods.addError) componentMethods.addError(componentConstants.language.LANG_ITEM_ADDED_TO_CART)
            if(!props.nativeSelect) componentMethods.setManagerState({showAddedToCartDialog: true})
          setAddedToCart(true)
          getShoppingCartTotal()
        }, 
        {}, 
        false, 
        () => {
          componentMethods.addError(componentConstants.language.LANG_ITEM_NOT_ADDED_TO_CART)
        })
    }
    let gridAlignment = {
      dimensionsGrid: {
        xs: 12,
        md: 12,
        lg: 12
      },
      quantityGrid: {
        xs: 6,
        md: 6,
        lg: 6
      },
      subtotalGrid: {
        xs: 6,
        md: 6,
        lg: 6
      }
    }
    if (props.nativeSelect) {

      gridAlignment = {
        dimensionsGrid: {
          xs: 12,
          md: 12,
          lg: 12
        },
        quantityGrid: {
          xs: 12,
          md: 12,
          lg: 12
        },
        subtotalGrid: {
          xs: 12,
          md: 12,
          lg: 12
        }
      }
    }
  return (<React.Fragment>
    {!loadingVariants?
              <Grid container>
                <Grid item {...gridAlignment.dimensionsGrid}>
                    {variants.map((el, index) => {
                      return <div onClick={() => {setVariant(el.variantsID)}} className={variant === el.variantsID ? classes.variantsSelected : classes.variantsItem} key={index}>
                                <div className={classes.variantsPrice}>{(parseInt(el.variantsDimensionsPrice)/100).toFixed(2)}{componentConstants.language.LANG_CURRENCY}</div>
                                <div className={classes.variantsDimensions}>{el.variantsWidth}{componentConstants.language.LANG_UNIT_OF_MEASURE} x {el.variantsHeight}{componentConstants.language.LANG_UNIT_OF_MEASURE} {componentConstants.language[el.variantsDimensionsPaperType]}</div>
                                <div><Typography component="div" variant="caption">{componentConstants.language.LANG_PRODUCT_CODE}: {el.variantsID} - {componentConstants.language.LANG_CLICK_TO_SELECT_VARIANT}</Typography></div>
                            </div>
                    })}
                </Grid>
                <Grid item {...gridAlignment.quantityGrid}>
                    {/* <TextField className={classes.quantityInput} inputProps={ {maxLength: 2} } label={componentConstants.language.LANG_QUANTITY} onChange={handleChangeQuantity} value={quantity} /> */}
                    <FormControl className={classes.formControl} fullWidth={true}>
                        {props.nativeSelect ? null : <InputLabel>{componentConstants.language.LANG_SELECT_QUANTITY}</InputLabel>}
                        <Select
                        native={props.nativeSelect}
                        value={quantity}
                        onChange={handleChangeQuantity}
                        >
                          {props.nativeSelect ?
                            <option>{componentConstants.language.LANG_SELECT_QUANTITY}</option>
                            :
                            null
                          }
                        {[1,2,3,4,5,6,7,8,9].map((el, index) => {
                        return (props.nativeSelect ?<option key={index} value={el}>{el}</option> : <MenuItem key={index} value={el}>{el}</MenuItem>)
                        })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item {...gridAlignment.subtotalGrid}>
                <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
              <Input
                disabled
                endAdornment={<InputAdornment position="end">{componentConstants.language.LANG_CURRENCY}</InputAdornment>}
                inputProps={{
                  'aria-label': 'weight',
                }}
                value={subtotal ? subtotal : 0}
              />
              <FormHelperText id="standard-weight-helper-text">{componentConstants.language.LANG_SUBTOTAL} </FormHelperText>
            </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button className={classes.button} color="secondary" onClick={postShoppingCartItemsAction} fullWidth component="div" variant="contained" startIcon={<AddShoppingCartIcon />}>
                        {componentConstants.language.LANG_ADD_TO_SHOPPING_CART}
                    </Button>
                </Grid>
                {(props.nativeSelect && addedToCart)?
                <Grid item xs={12} md={12} lg={12}>
                    {componentConstants.language.LANG_ITEM_ADDED_TO_CART}
                  <Link to="/checkout" className={classes.link}>
                    <Button className={classes.buttonLink} color="secondary" variant="contained">
                      {componentConstants.language.LANG_SHOPPING_CART}
                    </Button>
                  </Link>
                </Grid>
                :
                null}

        </Grid>
        :
        <CircularProgress color="secondary" />
      }
      </React.Fragment>
  );
}